import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import 'react-table/react-table.css';
import PropTypes from 'prop-types';

import DashboardTemplate from './template';

const DirectResponseDashboard = ({ data }) => {
  const pageTitle = 'DR Leaderboard';
  const pageDescription = 'Track DR leads and sales.';
  const summaryData = data.gwxapi.allDirectResponseAds;

  return (
    <DashboardTemplate
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      summaryData={summaryData}
    />
  );
};

DirectResponseDashboard.propTypes = {
  data: PropTypes.object.isRequired,
};
const DirectResponseDashboardWithQuery = () => (
  <StaticQuery
    query={graphql`
      query {
        gwxapi {
          allDirectResponseAds {
            name
            leads {
              email
              isConverted
            }
          }
        }
      }
    `}
    render={data => <DirectResponseDashboard data={data} />}
  />
);

export default DirectResponseDashboardWithQuery;
